import React, { useState, useEffect } from 'react'
import { NumericFormat } from 'react-number-format'
import {
  Typography, Button, IconButton, Chip, CircularProgress,
  Table, TableBody, TableRow, TableHead, TableContainer, TableCell,
  Dialog, DialogActions, DialogContent, DialogTitle, Pagination,
  Grid, TextField, FormGroup, FormControlLabel, Checkbox,
  FormControl, InputLabel, Select, MenuItem, ListItemText, OutlinedInput, Box
} from '@mui/material'
import { Add, Edit, DeleteForever, Search as SearchIcon, FileDownload } from '@mui/icons-material'
import { Colors } from '../styles/theme'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useAppContext } from '../context/AppProvider'
import Dropzone from './ImageUpload/Dropzone'
import { useS3 } from '../hooks/useS3'
import DeleteConfirmationDialog from './DeleteConfirmationDialog'
import { useItems } from '../hooks/useItems'
import { useSearchParams } from 'react-router-dom'

const validationData = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  price: Yup.number().required('Name is required').positive('Must be a positive number'),
  discount: Yup.mixed(),
  brand: Yup.string().required('Brand is required'),
  categories: Yup.array().required('Categories is required'),
  clearence: Yup.bool(),
  package: Yup.bool()
})

const keyOptions = [
  { name: 'Denumire', value: 'Name' },
  { name: 'Brand', value: 'Brand' },
  { name: 'Categorie', value: 'Category' },
  { name: 'Visibil', value: 'Visibility' },
  { name: 'Pachet', value: 'Package' },
  { name: 'Lichidare', value: 'Clearence' },
  { name: 'Stock', value: 'Stock' },
  { name: 'Pret', value: 'Price' }
]
const operatorOptions = {
  Name: [{ name: 'Egal', value: '=' }, { name: 'Nui egal', value: '!=' }],
  Brand: [{ name: 'Egal', value: '=' }, { name: 'Nui egal', value: '!=' }],
  Category: [{ name: 'Egal', value: '=' }, { name: 'Nui egal', value: '!=' }],
  Visibility: [{ name: 'Egal', value: '=' }],
  Package: [{ name: 'Egal', value: '=' }],
  Clearence: [{ name: 'Egal', value: '=' }],
  Stock: [
    { name: 'Mai mare', value: '>' },
    { name: 'Mai mic', value: '<' },
    { name: 'Mai mare sau egal', value: '>=' },
    { name: 'Mai mic sau egal', value: '<=' },
    { name: 'Egal', value: '=' },
    { name: 'Nui egal', value: '!=' }],
  Price: [
    { name: 'Mai mare', value: '>' },
    { name: 'Mai mic', value: '<' },
    { name: 'Mai mare sau egal', value: '>=' },
    { name: 'Mai mic sau egal', value: '<=' },
    { name: 'Egal', value: '=' },
    { name: 'Nui egal', value: '!=' }]
}

const Products = () => {
  const { state } = useAppContext()
  const [fileForUpload, setFileForUpload] = useState(null)
  const { uploadToS3, downloadFromS3 } = useS3()
  const [selectedKey, setSelectedKey] = useState('')
  const [selectedOperator, setSelectedOperator] = useState('')
  const [selectedValue, setSelectedValue] = useState('')
  const [filters, setFilters] = useState([])
  const [exportLoading, setExportLoading] = useState(false)

  const { addItems, updateItems, deleteItems } = useItems()

  const [open, setOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  }
  const products = state.items.data
  const categories = state.collections.categories.data
  const brands = state.collections.brands.data
  const discounts = state.collections.discount.data
  const [discountedPrice, setDiscountedPrice] = useState(0)
  const [initialValues, setInitialValues] = useState({
    _id: -1,
    name: '',
    description: '',
    price: 0,
    stock: 99,
    images: [],
    cover: '',
    clearence: false,
    package: false,
    discount: -1,
    brand: brands[0],
    ean: '',
    visible: false,
    categories: [categories[0]]
  })

  const handleAddProduct = () => {
    setInitialValues({
      _id: -1,
      name: '',
      description: '',
      price: 0,
      stock: 99,
      cover: '',
      images: [],
      clearence: false,
      package: false,
      discount: -1,
      brand: brands[0]._id,
      ean: '',
      visible: false,
      categories: [categories[0]._id]
    })
    setOpen(true)
  }

  useEffect(() => {
    if (brands[0] !== undefined) {
      setInitialValues(prev => { return { ...prev, brand: brands[0]._id } })
    }
  }, [brands])

  const { fetchItemsByEntityType, searchItems, exportItems } = useItems()

  const [page, setPage] = useState(1)
  const maxResult = 100
  const totalPages = state.items.total ? Math.ceil(state.items.total / maxResult) : 0
  const [searchParams, setSearchParams] = useSearchParams()

  const paginationHandleChange = (event, value) => {
    if (value !== page) {
      const updatedFilters = { ...Object.fromEntries(searchParams.entries()), page: parseInt(value, 10) }
      fetchItemsByEntityType(null, null, updatedFilters)
      setSearchParams(updatedFilters)
      setPage(parseInt(value, 10))
    }
  }

  const handleDelete = (product) => {
    deleteItems(product)
  }

  const handlePriceChange = (event, setFieldValue, values) => {
    const price = event.target.value
    setFieldValue('price', price)
    const discountId = values.discount
    const discount = discounts.find(d => d._id === discountId)
    if (discount) {
      const newPrice = price - (price * (discount.value / 100))
      setDiscountedPrice(newPrice)
    } else {
      setDiscountedPrice(price) // No discount selected
    }
  }

  const handleDiscountChange = (event, setFieldValue, values) => {
    const discountId = event.target.value || -1
    setFieldValue('discount', discountId)
    const discount = discounts.find(d => d._id === discountId)
    if (discount) {
      const newPrice = values.price - (values.price * (discount.value / 100))
      setDiscountedPrice(newPrice)
    } else {
      setDiscountedPrice(values.price) // No discount selected
    }
  }

  const handleEdit = (product) => {
    const discountId = product.collections.find(id => discounts.some(discount => discount._id === id))
    const discount = discounts.find(d => d._id === discountId)

    if (discount) {
      const newPrice = product.price - (product.price * (discount.value / 100))
      setDiscountedPrice(newPrice)
    } else {
      setDiscountedPrice(product.price)
    }
    const brandId = product.collections.find(id => brands.some(brand => brand._id === id))
    const categoryIds = categories
      .filter(category => product.collections.includes(category._id))
      .map(category => category._id)
    setInitialValues({
      ...product,
      discount: discountId || -1,
      brand: brandId || brands[0]._id,
      categories: categoryIds || [categories[0]._id],
      description: product.description.join('\n')
    })
    setOpen(true)
  }

  const handleSearch = async () => {
    const getBrandIdByName = (name) => {
      const brand = brands.find(b => b.name === name)
      return brand ? brand._id : name
    }

    const getCategoryIdByName = (name) => {
      const category = categories.find(c => c.name === name)
      return category ? category._id : name
    }

    const searchQuery = filters.map(f => {
      let value = f.value
      if (f.key === 'Brand') {
        value = getBrandIdByName(f.value)
      } else if (f.key === 'Category') {
        value = getCategoryIdByName(f.value)
      }
      return `(${f.key}${f.operator}${value})`
    }).join('-')

    const updatedFilters = { ...Object.fromEntries(searchParams.entries()), search: searchQuery }
    setSearchParams(updatedFilters)
    searchItems(searchQuery)
  }

  const handleExport = async () => {
    setExportLoading(true)
    const getBrandIdByName = (name) => {
      const brand = brands.find(b => b.name === name)
      return brand ? brand._id : name
    }

    const getCategoryIdByName = (name) => {
      const category = categories.find(c => c.name === name)
      return category ? category._id : name
    }

    const exportQuery = filters.map(f => {
      let value = f.value
      if (f.key === 'Brand') {
        value = getBrandIdByName(f.value)
      } else if (f.key === 'Category') {
        value = getCategoryIdByName(f.value)
      }
      return { key: f.key, operator: f.operator, value }
    })

    const presignedUrl = await exportItems(exportQuery)
    await downloadFromS3(presignedUrl)
    // await new Promise(resolve => setTimeout(resolve, 2000)) // Simulating a 2 seconds delay

    // console.log('LOADING')
    setExportLoading(false)
  }

  const handleAddFilter = () => {
    if (selectedKey && selectedOperator && selectedValue) {
      setFilters(prevFilters => [...prevFilters, { key: selectedKey, operator: selectedOperator, value: selectedValue }])
      setSelectedKey('')
      setSelectedOperator('')
      setSelectedValue('')
    }
  }

  const handleRemoveFilter = (index) => {
    setFilters(prevFilters => prevFilters.filter((_, i) => i !== index))
  }

  const handleOpenDeleteDialog = (item) => {
    setItemToDelete(item)
    setDeleteDialogOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false)
    setItemToDelete(null)
  }

  const handleConfirmDelete = () => {
    if (itemToDelete) {
      handleDelete(itemToDelete)
    }
    handleCloseDeleteDialog()
  }

  const handleSubmit = async (values) => {
    if (fileForUpload) {
      try {
        fileForUpload.map(file => uploadToS3(file).then(console.log('done?')))
      } catch (error) {
        console.error('File upload failed:', error)
      }
    }
    setOpen(false)
    const collectionArray = [values.brand, ...values.categories, ...(values.discount !== -1 ? [values.discount] : [])]
    const payload = {
      _id: values._id,
      name: values.name,
      description: [...values.description.split('\n')],
      price: parseInt(values.price, 10),
      stock: values.stock,
      images: values.images,
      cover: values.images[0],
      clearence: values.clearence,
      package: values.package,
      ean: values.ean,
      visible: values.visible,
      collections: collectionArray
    }
    if (values._id === -1) {
      await addItems(payload)
    } else {
      await updateItems(payload)
    }
  }

  const calculateDiscountedPrice = (price, discountRate) => {
    return price - (price * (discountRate / 100))
  }

  const renderPrice = (product) => {
    const discountId = product.collections.find(id => discounts.some(discount => discount._id === id))
    const discount = discounts.find(d => d._id === discountId)
    if (discount) {
      const discountedPrice = calculateDiscountedPrice(product.price, discount.value)
      return (
        <Typography variant='h6' sx={{ fontSize: '14px', fontWeight: 400 }}>
          {`Discount ${discount.value}%: `} <NumericFormat value={discountedPrice} displayType={'text'} thousandSeparator={true} decimalScale={1} suffix={' RON'} />
        </Typography>
      )
    } else {
      return (
        <NumericFormat value={product.price} displayType={'text'} thousandSeparator={true} suffix={' RON'} />
      )
    }
  }

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...'
    }
    return text
  }

  const renderDescription = (description) => {
    // Use an empty array as the default value if description is undefined
    const safeDescription = description ?? []
    return truncateText(safeDescription.join('\n'), 50) // You can adjust the length as needed
  }

  const ValueOptions = {
    Name: [],
    Brand: brands.map(brand => { return { name: brand.name, value: brand.name } }),
    Category: categories.map(cat => { return { name: cat.name, value: cat.name } }),
    Visibility: [{ name: 'Da', value: 'True' }, { name: 'Nu', value: 'False' }],
    Package: [{ name: 'Da', value: 'True' }, { name: 'Nu', value: 'False' }],
    Clearence: [{ name: 'Da', value: 'True' }, { name: 'Nu', value: 'False' }],
    Stock: [],
    Price: []
  }

  const filteredKeyOptions = keyOptions.filter(key => !filters.some(filter => filter.key === key.value && filter.key !== 'Category'))
  return (
    <>
    <Typography variant='h6' sx={{ marginBottom: '20px' }}>Produse</Typography>
      <Button startIcon={<Add />} variant='outlined' onClick={handleAddProduct} sx={{ color: Colors.fontColor.main, borderColor: Colors.borderColor.main, marginBottom: '20px' }}>Adaugă produs</Button>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
       <FormControl sx={{ minWidth: 120, marginRight: 2 }}>
          <InputLabel>Key</InputLabel>
          <Select value={selectedKey} onChange={(e) => setSelectedKey(e.target.value)}>
            {filteredKeyOptions.map(option => (
              <MenuItem key={option.name} value={option.value}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 120, marginRight: 2 }}>
          <InputLabel>Operator</InputLabel>
          <Select value={selectedOperator} onChange={(e) => setSelectedOperator(e.target.value)}>
            {selectedKey && operatorOptions[selectedKey].map(option => (
              <MenuItem key={option.name} value={option.value}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedKey && ValueOptions[selectedKey].length > 0
          ? (
          <FormControl sx={{ minWidth: 120, marginRight: 2, width: '100%' }}>
            <InputLabel>Value</InputLabel>
            <Select value={selectedValue} onChange={(e) => setSelectedValue(e.target.value)}>
              {ValueOptions[selectedKey].map(option => (
                <MenuItem key={option.name} value={option.value}>{option.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
            )
          : (
          <TextField
            variant="outlined"
            label="Value"
            value={selectedValue}
            onChange={(e) => setSelectedValue(e.target.value)}
            sx={{ minWidth: 120, marginRight: 2, width: '100%' }}
          />
            )}
        <Button variant="contained" onClick={handleSearch} startIcon={<SearchIcon />} sx={{ height: '40px', marginBottom: '0px' }}>
        Caută
      </Button>
      </Box>
      <Box sx={{ marginBottom: '20px', display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
        {filters.map((filter, index) => (
          <Chip
            key={index}
            label={`${filter.key} ${filter.operator} ${filter.value}`}
            onDelete={() => handleRemoveFilter(index)}
            color="primary"
            variant="outlined"
            sx={{
              marginRight: 1,
              marginBottom: 1,
              backgroundColor: '#f0f0f0', // Light gray background
              color: '#000', // Black text color
              borderColor: '#ccc' // Light gray border
            }}
          />
        ))}
      </Box>
      <Button variant="contained" onClick={handleAddFilter} sx={{ height: '40px', width: '180px' }}>
          Add Filter
      </Button>
      <Button
        startIcon={exportLoading ? <CircularProgress size={20} color="inherit" /> : <FileDownload />}
        variant='outlined'
        disabled={filters.length === 0 || exportLoading}
        onClick={handleExport}
        sx={{ color: Colors.fontColor.main, borderColor: Colors.borderColor.main, marginBottom: '0px' }}
      >
        {exportLoading ? 'Preparing download...' : 'Export CSV'}
      </Button>

      <TableContainer>
        <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nume</TableCell>
            <TableCell>Descriere</TableCell>
            <TableCell>Preț</TableCell>
            <TableCell>Lichidare</TableCell>
            <TableCell>Pachet</TableCell>
            <TableCell>Visible</TableCell>
            <TableCell>Acțiuni</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {products.map(product =>
            <TableRow key={product._id}>
              <TableCell>{product.name}</TableCell>
              <TableCell>{renderDescription(product.description)}</TableCell>
              <TableCell>{renderPrice(product)}</TableCell>
              <TableCell>{(product.clearence ?? '').toString()}</TableCell>
              <TableCell>{(product.package ?? '').toString()}</TableCell>
              <TableCell>{(product.visible ?? '').toString()}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleEdit(product)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => handleOpenDeleteDialog(product)}>
                  <DeleteForever sx={{ color: Colors.accent.main }}/>
                </IconButton>
              </TableCell>
            </TableRow>
            )}
        </TableBody>
        </Table>
      </TableContainer>
      <Box padding='25px 0'>
          <Pagination count={totalPages} page={page} onChange={paginationHandleChange} />
      </Box>
      <Dialog
      open={open}
      fullWidth
      maxWidth='lg'
      >
        <DialogTitle>
              {'Adaugă/modifică produsul'}
        </DialogTitle>
        <Formik initialValues={initialValues} validationSchema={validationData}
        onSubmit={handleSubmit}>
          {({ values, dirty, isValid, getFieldProps, setFieldValue }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name='name'
                    label='Nume produs'
                    size="small"
                    fullWidth
                    required
                  />
                  <ErrorMessage name='name'>
                    {(message) => (
                      <Typography color={'red'}>{message}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name='ean'
                    label='EAN code'
                    size="small"
                    fullWidth
                    required
                  />
                  <ErrorMessage name='ean'>
                    {(message) => (
                      <Typography color={'red'}>{message}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name='description'
                    label='Descriere produs'
                    multiline
                    rows={10}
                    fullWidth
                    required
                  />
                  <ErrorMessage name='description'>
                    {(message) => (
                      <Typography color={'red'}>{message}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name='price'
                    label='Preț'
                    size="small"
                    onChange={(event) => handlePriceChange(event, setFieldValue, values)}
                    fullWidth
                    required
                  />
                  <ErrorMessage name='price'>
                    {(message) => (
                      <Typography color={'red'}>{message}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                <Field name="categories">
                  {({ field, form }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="categories-select-label">Categories</InputLabel>
                      <Select
                        labelId="categories-select-label"
                        id="categories-select"
                        multiple
                        {...field}
                        value={field.value}
                        onChange={event => {
                          form.setFieldValue('categories', event.target.value)
                        }}
                        input={<OutlinedInput label="Categorie" />}
                        renderValue={selected => selected
                          .map(id => categories.find(category => category._id === id)?.name)
                          .join(', ')
                        }
                        MenuProps={MenuProps}
                      >
                      {categories.map(category => (
                          <MenuItem key={category._id} value={category._id}>
                            <Checkbox checked={field.value.includes(category._id)} color='fontColor' />
                            <ListItemText primary={category.name} />
                          </MenuItem>
                      ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
                  <ErrorMessage name='category'>
                    {(message) => (
                      <Typography color={'red'}>{message}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <Field name="brand">
                  {({ field, form }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="brand-select-label">Brand</InputLabel>
                      <Select
                        labelId="brand-select-label"
                        id="brand-select"
                        {...field}
                        label="Brand"
                        onChange={(event) => {
                          form.setFieldValue('brand', event.target.value)
                        }}
                        renderValue={(selectedId) => {
                          return brands.find(brand => brand._id === selectedId)?.name || ''
                        }}
                      >
                        {brands.map((brand) => (
                          <MenuItem key={brand._id} value={brand._id} sx={{ textTransform: 'capitalize' }}>
                            {brand.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
                <ErrorMessage name='brand'>
                  {(message) => (
                    <Typography color={'red'}>{message}</Typography>
                  )}
                </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <Field name="discount">
                  {({ field, form }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="discount-select-label">Discount</InputLabel>
                      <Select
                        labelId="discount-select-label"
                        id="discount-select"
                        {...field}
                        label="Discount"
                        onChange={event => handleDiscountChange(event, setFieldValue, values)}
                        renderValue={(selectedId) => {
                          const discountData = discounts.find(discount => discount._id === selectedId)
                          const textToRender = discountData?.name ? `${discountData.value}% Preț cu discount: ${discountedPrice.toFixed(1)}` : 'None'
                          return textToRender
                        }}
                      >
                        <MenuItem key={-1} value={-1}>
                          {'None'}
                        </MenuItem>
                        {discounts.map((discount) => (
                          <MenuItem key={discount._id} value={discount._id}>
                            {discount.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
                <ErrorMessage name='discount'>
                  {(message) => (
                    <Typography color={'red'}>{message}</Typography>
                  )}
                </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={Dropzone}
                    allowMultiple={true}
                    name='images'
                    onFilesChange={(files) => {
                      const fileNames = files.map(file => file.hashedName ? file.hashedName : file.preview)
                      setFieldValue('images', fileNames)
                      setFileForUpload(files.length ? files : null)
                    }}
                    className= 'brands-dragndrop'
                    existingLogoUrl={initialValues.images}
                  />
                  <ErrorMessage name='images'>
                    {(message) => (
                      <Typography color={'red'}>{message}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={2}>
                  <Field name='clearence'>
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { setFieldValue } // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    }) => (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color='fontColor'
                              {...field}
                              checked={field.value} // the value will be a boolean indicating checked state
                              onChange={(event) => setFieldValue('clearence', event.target.checked)} // update Formik state
                            />
                          }
                          label="Lichidare"
                        />
                      </FormGroup>
                    )}
                  </Field>
                  <ErrorMessage name='clearence'>
                    {(message) => <Typography color={'red'}>{message}</Typography>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={2}>
                  <Field name='package'>
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { setFieldValue } // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    }) => (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color='fontColor'
                              {...field}
                              checked={field.value} // the value will be a boolean indicating checked state
                              onChange={(event) => setFieldValue('package', event.target.checked)} // update Formik state
                            />
                          }
                          label="Pachet"
                        />
                      </FormGroup>
                    )}
                  </Field>
                  <ErrorMessage name='package'>
                    {(message) => <Typography color={'red'}>{message}</Typography>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={2}>
                  <Field name='visible'>
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { setFieldValue } // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    }) => (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color='fontColor'
                              {...field}
                              checked={field.value} // the value will be a boolean indicating checked state
                              onChange={(event) => setFieldValue('visible', event.target.checked)} // update Formik state
                            />
                          }
                          label="Visible"
                        />
                      </FormGroup>
                    )}
                  </Field>
                  <ErrorMessage name='visible'>
                    {(message) => <Typography color={'red'}>{message}</Typography>}
                  </ErrorMessage>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {
                getFieldProps('_id').value !== -1
                  ? (
                <Button
               disabled={!dirty || !isValid}
               type='submit' variant='contained' color='primary'>Save Edit</Button>
                    )
                  : (
                <Button
               disabled={!dirty || !isValid}
               type='submit' variant='contained' color='primary'>Save</Button>
                    )
               }
              <Button autoFocus variant='contained' onClick={() => setOpen(false)}>Cancel</Button>
            </DialogActions>
          </Form>

          )}
        </Formik>
      </Dialog>
      <DeleteConfirmationDialog
      open={deleteDialogOpen}
      onClose={handleCloseDeleteDialog}
      onConfirm={handleConfirmDelete}
      object={itemToDelete ? itemToDelete.name : ''}
    />
    </>
  )
}

export default Products
