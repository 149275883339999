import React, { useState, useRef } from 'react'
import {
  Typography,
  Box,
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Alert,
  Snackbar,
  Grid,
  useMediaQuery
} from '@mui/material'
import DocumentUploaderDropzone from './DocumentUpload/Dropzone'
import { useS3 } from '../hooks/useS3'
import { styled, useTheme } from '@mui/system'
import { useSync } from '../hooks/useSync'

const ProgressWithLabel = styled(LinearProgress)(({ theme, error }) => ({
  height: '15px',
  width: '100%',
  backgroundColor: error ? theme.palette.error.main : theme.palette.primary.main,
  '& .MuiLinearProgress-bar': {
    backgroundColor: error ? theme.palette.error.main : theme.palette.primary.dark
  }
}))

const SyncProducts = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { uploadToS3 } = useS3()
  const { getSyncData, addSync } = useSync()

  const [fileForUpload, setFileForUpload] = useState(null)
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [result, setResult] = useState(null)
  const [error, setError] = useState(false)
  const [previewData, setPreviewData] = useState(null)
  const [detailedPreview, setDetailedPreview] = useState(null)
  const [showDuplicates, setShowDuplicates] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [uniqueId, setUniqueId] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)
  const rowsPerPage = 10

  const dropzoneRef = useRef()

  const steps = ['Încărcați Fișierul', 'Previzualizați Schimbările', 'Confirmați și Sincronizați']

  const handlePreview = async () => {
    setLoading(true)
    setError(false)
    setResult(null)
    setProgress(0)

    if (fileForUpload) {
      try {
        await uploadToS3(fileForUpload)
      } catch (error) {
        console.error('Încărcarea fișierului a eșuat:', error)
        setError(true)
        setResult('Încărcarea fișierului a eșuat. Vă rugăm să încercați din nou.')
        setLoading(false)
        return
      }
    }

    setProgress(50)

    addSync({ file_key: fileForUpload.hashedName }, 'preview').then((resp) => {
      if (!resp.errors) {
        setProgress(100)
        setResult('Previzualizarea a fost completată cu succes!')
        setFileForUpload(null)

        if (resp.data && resp.data.length) {
          const { unique_id, summary } = resp.data[0]
          setPreviewData(summary)
          setUniqueId(unique_id)
        }
        setActiveStep(1)
        if (dropzoneRef.current) {
          dropzoneRef.current.resetFiles()
        }
      } else {
        setProgress(95)
        setError(true)
        setResult('Previzualizarea a eșuat. Vă rugăm să încercați din nou.')
      }
      setLoading(false)
    })
  }

  const fetchDetailedPreview = async () => {
    if (!uniqueId) return

    setLoading(true)
    getSyncData({ unique_id: uniqueId }, 'get_preview').then((resp) => {
      if (!resp.errors) {
        setDetailedPreview(resp.data[0])
        setShowDuplicates(true)
      } else {
        setError(true)
        setResult('Nu s-a putut obține previzualizarea detaliată.')
      }
      setLoading(false)
    })
  }

  const handleSync = async () => {
    setLoading(true)
    setError(false)
    setResult(null)

    addSync({ unique_id: uniqueId }, 'sync').then((resp) => {
      if (!resp.errors) {
        setResult('Sincronizarea a fost finalizată cu succes!')
        setActiveStep(2)
        // Resetăm toate state-urile după un scurt timp pentru a permite utilizatorului să vadă mesajul de succes
        setTimeout(() => {
          setFileForUpload(null)
          setPreviewData(null)
          setDetailedPreview(null)
          setUniqueId(null)
          setActiveStep(0)
          setCurrentPage(0)
        }, 2000)
      } else {
        setError(true)
        setResult('Sincronizarea a eșuat. Vă rugăm să încercați din nou.')
      }
      setLoading(false)
    })
  }

  const paginatedData = (data) => {
    const start = currentPage * rowsPerPage
    const end = start + rowsPerPage
    return data.slice(start, end)
  }

  const handleCloseSnackbar = () => {
    setResult(null)
    setError(false)
  }

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1)
      setPreviewData(null)
      setDetailedPreview(null)
      setUniqueId(null)
      setShowDuplicates(false)
    }
  }

  const toggleDuplicates = () => {
    if (showDuplicates) {
      setShowDuplicates(false)
    } else {
      if (!detailedPreview) {
        fetchDetailedPreview()
      } else {
        setShowDuplicates(true)
      }
    }
  }

  return (
    <>
      <Typography variant="h4" sx={{ marginBottom: '20px', textAlign: 'center' }}>
        Sincronizează Produse
      </Typography>
      <Stepper activeStep={activeStep} alternativeLabel={!isMobile} sx={{ marginBottom: '20px' }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{isMobile ? '' : label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box
        sx={{
          width: '100%',
          maxWidth: '800px',
          margin: '0 auto',
          padding: '20px'
        }}
      >
        {activeStep === 0 && (
          <>
            <Typography variant="h6" sx={{ marginBottom: '20px' }}>
              Pasul 1: Încărcați Fișierul Excel
            </Typography>
            <DocumentUploaderDropzone
              ref={dropzoneRef}
              onFilesChange={(files) => {
                setLoading(false)
                setFileForUpload(files.length ? files[0] : null)
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handlePreview}
              disabled={!fileForUpload || loading}
              sx={{ marginTop: '20px' }}
            >
              Previzualizează Schimbările
            </Button>
          </>
        )}

        {activeStep === 1 && (
          <>
            <Typography variant="h6" sx={{ marginBottom: '20px' }}>
              Pasul 2: Revizuiți Schimbările
            </Typography>
            {previewData && (
              <Box sx={{ marginBottom: '20px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                      <Typography variant="h6">Duplicate</Typography>
                      <Typography variant="h4" color="error">
                        {previewData.total_duplicates}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                      <Typography variant="h6">Actualizări</Typography>
                      <Typography variant="h4" color="warning">
                        {previewData.total_updates}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                      <Typography variant="h6">Intrări Noi</Typography>
                      <Typography variant="h4" color="success">
                        {previewData.total_new_entries}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
              <Button variant="drawer" onClick={handleBack} disabled={loading}>
                Înapoi
              </Button>
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <Button
                  variant="drawer"
                  color="primary"
                  onClick={toggleDuplicates}
                  disabled={!uniqueId || loading}
                >
                  {showDuplicates ? 'Ascunde Duplicatele' : 'Afișează Duplicatele'}
                </Button>
              </Box>
            </Box>
            {showDuplicates && detailedPreview && (
              <>
                <Typography variant="h6" sx={{ marginTop: '20px' }}>
                  Duplicate Găsite
                </Typography>
                <TableContainer
                  component={Paper}
                  sx={{ maxHeight: 400, overflowY: 'auto', marginTop: '10px' }}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Număr Rând(uri)</TableCell>
                        <TableCell>EAN</TableCell>
                        <TableCell>Nume</TableCell>
                        <TableCell>Preț</TableCell>
                        <TableCell>Stoc</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedData(detailedPreview.duplicates || []).map((dup, index) => (
                        <TableRow key={`dup-${index}`} hover>
                          <TableCell>
                            {dup.row_numbers ? dup.row_numbers.join(', ') : dup.row_number}
                          </TableCell>
                          <TableCell>{dup.ean}</TableCell>
                          <TableCell>{dup.name}</TableCell>
                          <TableCell>{dup.price}</TableCell>
                          <TableCell>{dup.stock}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* Pagination controls */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
                    disabled={currentPage === 0}
                  >
                    Anterior
                  </Button>
                  <Typography>
                    Pagina {currentPage + 1} din{' '}
                    {Math.ceil((detailedPreview.duplicates || []).length / rowsPerPage)}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      setCurrentPage((prev) =>
                        (currentPage + 1) * rowsPerPage <
                        (detailedPreview.duplicates || []).length
                          ? prev + 1
                          : prev
                      )
                    }
                    disabled={
                      (currentPage + 1) * rowsPerPage >=
                      (detailedPreview.duplicates || []).length
                    }
                  >
                    Următor
                  </Button>
                </Box>
              </>
            )}
            <Box sx={{ marginTop: '40px', textAlign: 'center' }}>
              <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                Când sunteți pregătit, apăsați butonul de mai jos pentru a începe procesul de
                sincronizare.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSync}
                disabled={loading}
                size="large"
              >
                Confirmați și Începeți Sincronizarea
              </Button>
            </Box>
          </>
        )}

        {activeStep === 2 && (
          <>
            <Typography variant="h6" sx={{ marginBottom: '20px' }}>
              Pasul 3: Sincronizare Finalizată
            </Typography>
            <Alert severity="success">Produsele au fost sincronizate cu succes!</Alert>
          </>
        )}

        {loading && (
          <Box sx={{ width: '100%', marginTop: '20px' }}>
            <ProgressWithLabel
              variant="determinate"
              value={progress}
              error={error || undefined}
            />
          </Box>
        )}
      </Box>
      <Snackbar
        open={!!result}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={error ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {result}
        </Alert>
      </Snackbar>
    </>
  )
}

export default SyncProducts
