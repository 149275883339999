import { useContext, useCallback } from 'react'
import { AppContext } from '../context/AppProvider'

export const useSync = () => {
  const { state } = useContext(AppContext)

  const apiUrl = 'https://private-api.primusvest.ro/v1/sync'

  const getSyncData = useCallback(async (payload, action) => {
    const token = localStorage.getItem('token')
    let headers = { 'Content-Type': 'application/json' }

    const { unique_id } = payload
    if (token) {
      headers = { ...headers, Authorization: `${token}` }
      try {
        const response = await fetch(`${apiUrl}?uniqueId=${unique_id}`, {
          headers: { ...headers, Authorization: `${token}` }
        })

        if (response.ok) {
          const { data, errors } = await response.json()
          return { data, errors }
        } else {
          console.error('Error with status code:', response.status)
          console.log(response)
          return { data: [], errors: [response.status] }
        }
      } catch (error) {
        console.error('Sync error:', error)
        return { data: [], errors: [error.message] }
      }
    } else {
      return { data: [], errors: ['Unauthorized: Missing token'] }
    }
  }, [apiUrl])

  const addSync = useCallback(async (payload, action) => {
    const token = localStorage.getItem('token')
    let headers = { 'Content-Type': 'application/json' }

    if (token) {
      headers = { ...headers, Authorization: `${token}` }
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers,
          body: JSON.stringify({ action, ...payload })
        })

        if (response.ok) {
          const { data, errors } = await response.json()
          return { data, errors }
        } else {
          console.error('Error with status code:', response.status)
          return { data: [], errors: [response.status] }
        }
      } catch (error) {
        console.error('Sync error:', error)
        return { data: [], errors: [error.message] }
      }
    } else {
      return { data: [], errors: ['Unauthorized: Missing token'] }
    }
  }, [apiUrl])

  return { state, addSync, getSyncData }
}
